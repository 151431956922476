/* You can add global styles to this file, and also import other style files */

.gap-1 {
    gap: 4px;
}

.gap-2 {
    gap: 8px;
}

.gap-3 {
    gap: 12px
}

.gap-4 {
    gap: 16px;
}

.gap-5 {
    gap: 20px
}