/*!

=========================================================
* Now UI Dashboard Pro Angular - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-angular
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

@import "now-ui-dashboard/variables";
@import "now-ui-dashboard/mixins";

// Core CSS
@import "now-ui-dashboard/buttons";
@import "now-ui-dashboard/social-buttons";
@import "now-ui-dashboard/inputs";
@import "now-ui-dashboard/typography";
@import "now-ui-dashboard/misc";
@import "now-ui-dashboard/navbar";
@import "now-ui-dashboard/dropdown";
@import "now-ui-dashboard/alerts";
@import "now-ui-dashboard/images";
@import "now-ui-dashboard/nucleo-outline";
@import "now-ui-dashboard/tables";
@import "now-ui-dashboard/footers";
@import "now-ui-dashboard/fixed-plugin";

// components
@import "now-ui-dashboard/checkboxes-radio";
@import "now-ui-dashboard/progress";
@import "now-ui-dashboard/badges";
@import "now-ui-dashboard/pagination";
@import "now-ui-dashboard/info-areas";
@import "now-ui-dashboard/pills";
@import "now-ui-dashboard/tabs";
@import "now-ui-dashboard/rtl";
@import "now-ui-dashboard/popups";
@import "now-ui-dashboard/modals";
@import "now-ui-dashboard/carousel";
@import "now-ui-dashboard/sidebar-and-main-panel";
@import "now-ui-dashboard/timeline";

// cards
@import "now-ui-dashboard/cards";
@import "now-ui-dashboard/cards/card-chart";
@import "now-ui-dashboard/cards/card-user";
@import "now-ui-dashboard/cards/card-plain";

@import "now-ui-dashboard/cards/card-background";
@import "now-ui-dashboard/cards/card-collapse";
@import "now-ui-dashboard/cards/card-map";
@import "now-ui-dashboard/cards/card-contributions";
@import "now-ui-dashboard/cards/card-info-area";
@import "now-ui-dashboard/cards/card-lock";
@import "now-ui-dashboard/cards/card-pricing";
@import "now-ui-dashboard/cards/card-profile";
@import "now-ui-dashboard/cards/card-signup";
@import "now-ui-dashboard/cards/card-stats-mini";
@import "now-ui-dashboard/cards/card-stats";
@import "now-ui-dashboard/cards/card-subcategories";
@import "now-ui-dashboard/cards/card-testimonials";
@import "now-ui-dashboard/cards/card-wizard";

// Plugins CSS
@import "now-ui-dashboard/plugins/plugin-bootstrap-switch";
@import "now-ui-dashboard/plugins/plugin-nouislider";
@import "now-ui-dashboard/plugins/plugin-animate-bootstrap-notify";
@import "now-ui-dashboard/plugins/plugin-perfect-scrollbar";
@import "now-ui-dashboard/plugins/plugin-sweetalert2";
@import "now-ui-dashboard/plugins/plugin-card-wizard";
@import "now-ui-dashboard/plugins/plugin-datetimepicker";
@import "now-ui-dashboard/plugins/plugin-bootstrap-select";
@import "now-ui-dashboard/plugins/plugin-jasny-fileupload";
@import "now-ui-dashboard/plugins/plugin-tagsinput";
@import "now-ui-dashboard/plugins/plugin-datatables.net";
@import "now-ui-dashboard/plugins/plugin-jquery.jvectormap";
@import "now-ui-dashboard/plugins/plugin-fullcalendar";

// example pages and sections
@import "now-ui-dashboard/example-pages";
@import "now-ui-dashboard/sections";

@import "now-ui-dashboard/responsive";
@import "now-ui-dashboard/media-queries";

// angular differences
@import "now-ui-dashboard/angular/angular-differences";
